<template>
  <b-card :title="isEditMode ? 'Edit Module' : 'Add Module'" class="m-2">
    <div>
      <b-card v-if="loading" class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </b-card>

      <b-card v-if="saving" class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </b-card>

      <b-form @submit="onSubmit" v-if="!loading && !saving">
        <b-card title="Module Details" class="mb-2">
          <b-form-group
            id="input-group-program"
            label="Program:"
            label-for="input-program"
          >
            <b-form-select
              v-model="module.programId"
              id="input-program"
              placeholder="Select program"
              :options="program_options">
            </b-form-select>
          </b-form-group>
          <b-form-group
            id="input-group-title"
            label="Text:"
            label-for="input-title"
          >
            <b-form-input
              id="input-title"
              required
              placeholder="Enter title."
              v-model="module.title"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-description"
            label="Description:"
            label-for="input-description"
          >
            <b-form-input
              id="input-description"
              placeholder="Enter description."
              v-model="module.description"
            ></b-form-input>
          </b-form-group>
          <b-form-checkbox
            id="checkbox-published"
            v-model="module.published"
            name="checkbox-published"
          >
            Published
          </b-form-checkbox>
          <hr v-if="isEditMode"/>
          <div class="mt-4" v-if="isEditMode">
            <questions-card :moduleId="module && module._id ? module._id.toString() : undefined"/>
          </div>
        </b-card>

        <b-button type="submit" variant="primary" class="mr-1" size="sm">
          <b-icon icon="check-circle-fill" aria-hidden="true"></b-icon>
          {{ isEditMode ? 'Save Changes' : 'Add' }}
        </b-button>

        <b-button @click="$router.go(-1)" variant="danger" size="sm">
          <b-icon icon="x-circle-fill" aria-hidden="true"></b-icon>
          Cancel
        </b-button>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import API from "@/api";

import QuestionsCard from '@/components/QuestionsCard.vue';
export default {
  name: "AdminModuleEdit",
  components: {
    QuestionsCard
  },
  data: () => ({
    isEditMode: false,
    module: {},
    program_options: [],
    loading: false,
    saving: false,
    from: null
  }),
  created() {
    this.isEditMode = !!this.$route.params.id;
    this.module.programId = this.$route.params.programId || '';
    this.fetchData();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.from = from;
    })
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();

      this.saving = true;

      if (!this.module._id) {
        this.module.order = parseInt(this.module.order, 10);
        this.module.lastUpdated = new Date().getTime();
        let result = await API.insert("modules", this.module).catch((error) => {
          console.log(error);
        });
        console.log(result);
      } else {
        let filter = {
          _id: this.module._id,
        };

        let updateData = { ...this.module };
        delete updateData._id;
        let update = { $set: updateData };
        let result = await API.update("modules", filter, update).catch(
          (error) => {
            console.log(error);
          }
        );
        console.log(result);
      }
      await API.refresh();

      if (this.from && this.from.name === "AdminProgramEdit") {
        this.$router.push(`/admin/programs/edit/${this.module.programId}`);
      } else {
        this.$router.push("/admin/modules");
      }
    },
    async fetchData() {
      this.loading = true;

      const BSON = require("bson");
      const realmApp = API.realmApp;

      const mongo = realmApp.services.mongodb("mongodb-atlas");
      if (this.isEditMode) {
        const mongoCollection = mongo.db("eunoe").collection("modules");

        let moduleId = new BSON.ObjectID(this.$route.params.id);
        let filter = {
          _id: moduleId,
        };

        const result = await mongoCollection.findOne(filter);

        if (result != null) {
          this.module = result;
        }
      }
      const programsCollection = mongo.db("eunoe").collection("programs");
      const programs = await programsCollection.find();
      this.program_options = programs.map(program => ({value: program._id.toString(), text: `${program.title} (${program._id.toString()})`}));

      this.loading = false;
    },
  },
};
</script>
