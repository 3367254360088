<template>
  <b-card title="Admin Dashboard" class="m-2">
    <modules-card/>
  </b-card>
</template>

<script>
import ModulesCard from '@/components/sections/ModulesCard.vue';
export default {
  components: { ModulesCard },
  name: "AdminModules"
};
</script>